export default function Achievements() {
  return (
    
    <div className="bg-gray-100 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto sm:text-center">
        {/* <div className="lg:max-w-lg"> */}
          <h2 className="text-3xl font-bold tracking-tight text-cyan-700 sm:text-4xl">Our Legacy of Excellence</h2>
          <p className="mt-6 text-base leading-8 text-gray-600">
          We take immense pride in showcasing our collection of accolades that reflect our commitment to excellence. Each award in our gallery is a testament to the hard work and dedication that has driven our team to consistently deliver outstanding results. From the sleek and modern designs of our trophies to the distinguished plaques, these honors are more than just symbols of success—they are milestones that narrate our journey towards becoming industry leaders.</p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
            <img className="w-11/12 mt-4 mx-auto" src="http://seaircon.com/assets/Achievements/achievements1.png" alt="Awards" />

        </div>
      </div>
    </div>
  )
}
