import { EyeIcon, RocketLaunchIcon } from '@heroicons/react/24/outline'

export default function AboutUs() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-3xl font-semibold leading-7 text-cyan-700">About Us</p>
              {/* <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">A better workflow</h1> */}
              <p className="mt-6 text-xl leading-8 text-gray-700">
              Established in 1990, S E Aircon Pvt. Ltd. (earlier a partnership concern, popularly known as Shubh Engineers) is a company, which focuses only on providing 
              the air-conditioning solutions, thru sales and servicing of air conditioners and central air-conditioning projects.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
            <h2 className="mt-16 text-xl font-bold tracking-tight text-cyan-700">Authorized Dealers of</h2><br /><br /><br />

            <div className="grid grid-cols-1 gap-2">
                <div>
                    <img src="https://seaircon.com/assets/New/carrier.png" alt="Carrier Logo" className="w-3/4 h-auto" />
                </div> <br /> <br /><br />
                <div>
                    <img src="https://seaircon.com/assets/New/toshiba.png" alt="Toshiba Logo" className="w-3/4 h-auto" />
                </div>
            </div>
          {/* <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png"
            alt=""
          /> */}
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>
              SE Aircon is a rapidly expanding company dedicated to offering innovative solutions in refrigeration and air conditioning systems. As a group with a 
              turnover of 200 million, we anticipate reaching a turnover of 250 million ni the fiscal year 2023-24.
              </p>
              <p>
              With cutting-edge technology and an expanding business scope in the market, we have entered the industry under the leadership of a Second Generation Technocrat. 
              Our operations are carried out in collaboration with our sister concern, Rasair Engineers Pvt Ltd, which serves as the Exclusive Channel Partner for 
              Daikin Air Conditioning Pvt Ltd. This strategic partnership enables us to provide top-quality products and services to our clients, 
              ensuring excellence in every project.
              </p>
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <EyeIcon className="mt-1 h-6 w-6 flex-none text-cyan-700" aria-hidden="true" />
                  <span className='text-lg'>
                    <strong className="font-semibold text-gray-900">Our Vision</strong> <br /> SE Aircon Pvt. Ltd. aims to design, install, service, and maintain cost effective, 
                    customised modern refrigeration and air conditioning services. The company's long term vision is to be more creative, understanding and more customers focused thus developing a long and healthy working relationship.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <RocketLaunchIcon className="mt-1 h-6 w-6 flex-none text-cyan-700" aria-hidden="true" />
                  <span className='text-lg'>
                    <strong className="font-semibold text-gray-900">Our Mission</strong> <br /> The company's long term vision is to be more creative, understanding and 
                    more customers focused thus developing a long and healthy working relationship.
                  </span>
                </li>
                {/* <li className="flex gap-x-3">
                  <ServerIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Database backups.</strong> Ac tincidunt sapien
                    vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.
                  </span>
                </li> */}
              </ul>
              {/* <p className="mt-8">
                Et vitae blandit facilisi magna lacus commodo. Vitae sapien duis odio id et. Id blandit molestie auctor
                fermentum dignissim. Lacus diam tincidunt ac cursus in vel. Mauris varius vulputate et ultrices hac
                adipiscing egestas. Iaculis convallis ac tempor et ut. Ac lorem vel integer orci.
              </p>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">No server? No problem.</h2>
              <p className="mt-6">
                Id orci tellus laoreet id ac. Dolor, aenean leo, ac etiam consequat in. Convallis arcu ipsum urna nibh.
                Pharetra, euismod vitae interdum mauris enim, consequat vulputate nibh. Maecenas pellentesque id sed
                tellus mauris, ultrices mauris. Tincidunt enim cursus ridiculus mi. Pellentesque nam sed nullam sed diam
                turpis ipsum eu a sed convallis diam.
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
