import React, { useState } from 'react'

const products = {
    Residential: [
      {
        id: 1,
        name: 'Split Air Conditioner',
        description: '',
        imageUrl: 'http://seaircon.com/assets/New/splitac.png',
      },
      {
        id: 2,
        name: 'Window Air Conditioner',
        description: '',
        imageUrl: 'http://seaircon.com/assets/New/windowac.png',
      },
      {
        id: 3,
        name: 'Cassette Air Conditioner',
        description: '',
        imageUrl: 'http://seaircon.com/assets/New/cassetteac.png',
      },
      {
        id: 4,
        name: 'Ductable Air Conditioner',
        description: '',
        imageUrl: 'http://seaircon.com/assets/New/ductableac.png',
      },
      {
        id: 5,
        name: 'Chiller Air Conditioner',
        description: '',
        imageUrl: 'http://seaircon.com/assets/New/chillerac.png',
      },
      {
        id: 6,
        name: 'VRV Air Conditioner',
        description: '',
        imageUrl: 'http://seaircon.com/assets/New/vrcac.png',
      },
      // ...other residential products
    ],
    Commercial: [
      {
        id: 1,
        name: 'Water Cooled Chiller Plant',
        description: '',
        imageUrl: 'http://seaircon.com/assets/New/watercooledchillerplant.png',
      },
      {
        id: 2,
        name: 'Air Cooled Chiller Plant',
        description: '',
        imageUrl: 'http://seaircon.com/assets/New/aircooledchillerplant.png',
      },
      {
        id: 3,
        name: 'VRF Systems Variable Refergerant Flow System',
        description: '',
        imageUrl: 'http://seaircon.com/assets/New/ductableac.png',
      },
      {
        id: 4,
        name: "Air Side AHU's",
        description: '',
        imageUrl: 'http://seaircon.com/assets/New/airsideahu.png',
      },
    //   {
    //     id: 1,
    //     name: 'Water Cooled Chiller Plant',
    //     description: '',
    //     imageUrl: 'http://seaircon.com/assets/New/watercooledchillerplant.png',
    //   },
    //   {
    //     id: 1,
    //     name: 'Water Cooled Chiller Plant',
    //     description: '',
    //     imageUrl: 'http://seaircon.com/assets/New/watercooledchillerplant.png',
    //   },
      // ...other commercial products
    ],
  };
  
  const ProductCard = ({ product }) => {
    return (
      <div className="max-w-sm bg-white rounded-lg shadow-md overflow-hidden">
        <img className="w-full" src={product.imageUrl} alt={product.name} />
        <div className="p-5">
          <h5 className="text-lg font-semibold mb-3">{product.name}</h5>
          {product.description && <p className="text-gray-600 text-sm">{product.description}</p>}
        </div>
      </div>
    );
  };
  
  const ProductsGrid = () => {
    const [activeCategory, setActiveCategory] = useState('Residential');
  
    return (
    <div className="container mx-auto p-6">
      <h2 className="text-4xl font-semibold leading-7 text-cyan-700 mb-4 text-center mt-8">Our Products</h2>
      <br />
      <div className="flex justify-center gap-4 mb-8">
        <button
          className={`px-6 py-2 rounded-lg font-semibold text-lg ${activeCategory === 'Residential' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveCategory('Residential')}
        >
          Residential & Light Commercial
        </button>
        <button
          className={`px-6 py-2 rounded-lg font-semibold text-lg ${activeCategory === 'Commercial' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
          onClick={() => setActiveCategory('Commercial')}
        >
          Building Systems & Services
        </button>
      </div>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {products[activeCategory].map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
      </div>
    </div>
    );
  };
  
  export default ProductsGrid;