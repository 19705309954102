const clients = [
    {
      name: 'Footwear Design & Development in India',
      imageUrl: 'https://seaircon.com/assets/New/FDDI.png', 
    },
    {
        name: 'Reliance Industries',
        imageUrl: 'https://seaircon.com/assets/New/Reliancecopy.png', 
    },
    {
        name: 'Delhi Metro Rail Corporation',
        imageUrl: 'https://uitpsummit.org/wp/wp-content/uploads/221947187_216253916-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled-scaled.jpg',
    },
  ];

  const otherClients = [
    { name: 'Tezu Airport' },
    { name: 'cure.fit' },
    { name: 'GAIL (India) Limited' },
    { name: 'Essel Infra' },
    { name: 'Essel Energy' },
    { name: 'Gas Authority of India' },
    { name: 'Yes Bank' },
    { name: 'ICICI Bank' },
    { name: 'Theos' },
    { name: 'Glued' },
    { name: 'Albert David' },
    { name: 'ITC Limited' },
    { name: 'Dabur' },
    { name: 'Raymonds' },
    { name: 'Suncity Projects' },
    { name: 'Mahagun' },
    { name: 'Lions Eye Hospital' },
    { name: 'Pantaloons' },
    { name: 'Haldiram' },
    { name: 'Gurukul' },
    { name: 'Naukri.com' },
    { name: 'Anytime Fitness' },
    { name: 'BSNL' },
    // ... add other clients here
  ];


  
  export default function Projects() {
    return (
      <div className="bg-white p-8">
        <div className="text-center">
        {/* <div className="lg:max-w-lg"> */}
          <h2 className="text-4xl font-semibold leading-7 text-cyan-700 mb-8">Our Clients</h2>
          <p className="mt-2 text-lg text-gray-600">We’re a dynamic group of individuals who are passionate about what we do and dedicated to delivering the best results for our clients.</p>
        </div>
        <div className="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {clients.map((client) => (
            <div key={client.name} className="flex flex-col items-center justify-center h-full text-center">
              <div className="flex items-center justify-center h-3/4">
                <img className="mx-auto object-contain max-h-48" src={client.imageUrl} alt={client.name} />
              </div>
            <h3 className="mt-auto text-xl font-bold text-gray-900">{client.name}</h3>
        </div>
  ))}
</div>

  <div className="container mx-auto px-6 py-12">
    <h2 className="text-3xl font-medium leading-7 text-cyan-700 text-center">Other Clients</h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
      {otherClients.map((client, index) => (
        <div key={index} className="space-y-4 text-center">
          <div className="space-y-2">
            <div className="text-lg leading-6 font-medium space-y-1">
              <h3>{client.name}</h3>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>


      </div>
    );
  }
  