const testimonials = [
    {
      id: 1,
      name: '',
      username: '',
      content: "Ashok was out the other day to evaluate our air conditioner. I felt that he was very polite and knowledgeable and made an effort to thoroughly explain the issues we had. We ended up needing to replace our equipment and he seemed very open and honest and didn't try to upsell us on anything. I highly recommend SE Aircon.",
    },
    {
      id: 2,
      name: '',
      username: '',
      content: "SE Aircon management and technicians were fantastic! Top notch customer service and they did a great job. Highly recommend them.",
    },
    {
      id: 3,
      name: '',
      username: '',
      content: "Best AC guys around! We are super pleased with fast response and great work.",
    },
    // Add other testimonials here following the same structure
  ];
  
  const TestimonialCard = ({ testimonial }) => {
    return (
      <div className="bg-white p-6 rounded-lg shadow-md">
        <p className="text-gray-600 text-sm mb-4">{testimonial.content}</p>
        <div className="flex items-center">
          <div className="flex-shrink-0">
            {/* Insert an avatar image here if needed */}
          </div>
          <div className="ml-3">
            <p className="text-sm font-semibold">{testimonial.name}</p>
            <p className="text-sm text-gray-500">{testimonial.username}</p>
          </div>
        </div>
      </div>
    );
  };
  
  const TestimonialsGrid = () => {
    return (
      <section className="bg-gray-100 p-8">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center text-cyan-700 mb-8">
            We have worked with thousands of amazing people
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {testimonials.map((testimonial) => (
              <TestimonialCard key={testimonial.id} testimonial={testimonial} />
            ))}
          </div>
        </div>
      </section>
    );
  };
  
  export default TestimonialsGrid;