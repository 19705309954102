import { BuildingOfficeIcon, PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/outline'


export default function ContactUs() {
    return (
      <div className="min-h-screen flex flex-col">
        <div className="bg-gray-200 p-10 w-full">
          <div className="max-w-4xl mx-auto">
            <h2 className="text-3xl font-bold text-gray-700">Get in touch</h2>
            <p className="text-gray-600 mt-4">
              Reach out to us with your inquiries
            </p>
            <div className="mt-6">
              <div className="flex items-center text-gray-600 mt-4">
                  <BuildingOfficeIcon className="mt-1 h-6 w-6 flex-none text-gray-600" aria-hidden="true" />
                  <span className='text-base'>
                  &nbsp;&nbsp;&nbsp;G-313, Sector 63, Noida, UP 201301
                  </span>
              </div>
              <div className="flex items-center text-gray-600 mt-4">
                <PhoneIcon className="mt-1 h-6 w-6 flex-none text-gray-600" aria-hidden="true" />
                <span className='text-base'>
                &nbsp;&nbsp;&nbsp;+91 9311885464
                </span>
              </div>
              <div className="flex items-center text-gray-600 mt-4">
                <EnvelopeIcon className="mt-1 h-6 w-6 flex-none text-gray-600" aria-hidden="true" />
                <span className='text-base'>
                &nbsp;&nbsp;&nbsp;noida@seaircon.com
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-grow w-full bg-white p-10">
          <div className="max-w-4xl mx-auto">
            <form action="https://api.web3forms.com/submit" method="POST" className="space-y-6">
            <input type="hidden" name="access_key" value="5ff35093-6fab-48c2-b543-cb38d7dceaa3" />
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                    First name
                  </label>
                  <input
                    className="mt-1 bg-gray-100 border-2 border-gray-300 text-gray-700 rounded-lg py-3 px-4 w-full"
                    id="first-name"
                    type="text"
                    placeholder="John"
                    name="name"
                  />
                </div>
                <div>
                  <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                    Last name
                  </label>
                  <input
                    className="mt-1 bg-gray-100 border-2 border-gray-300 text-gray-700 rounded-lg py-3 px-4 w-full"
                    id="last-name"
                    type="text"
                    placeholder="Doe"
                    name="last_name"
                  />
                </div>
              </div>
              
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Email
              </label>
              <input
                className="mt-1 bg-gray-100 border-2 border-gray-300 text-gray-700 rounded-lg py-3 px-4 w-full"
                id="email"
                type="email"
                placeholder="you@example.com"
                name="email"
              />
              
              <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                Phone number
              </label>
              <input
                className="mt-1 bg-gray-100 border-2 border-gray-300 text-gray-700 rounded-lg py-3 px-4 w-full"
                id="phone"
                type="tel"
                placeholder="5551234567"
                name="phone"
              />
              
              <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                Message
              </label>
              <textarea
                className="mt-1 bg-gray-100 border-2 border-gray-300 text-gray-700 rounded-lg py-3 px-4 w-full"
                id="message"
                placeholder="Your message"
                rows="4"
                name="message"
              ></textarea>
              
              <div className="text-right mt-6">
                <button
                  type="submit"
                  className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg"
                >
                  Send message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }